.rna-container {
  z-index: 1051;
  position: fixed;
}
.alert {
  button.close {
    margin-top: -1px;
    color: $white;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
    &::after {
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      vertical-align: middle;
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
      content: "\f00d";
    }
    span {
      display: none !important;
    }
  }
  &.alert-dismissible {
    padding-right: 1.25rem;
  }
}
