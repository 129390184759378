%ticket-card {
  hr {
    margin: 5px 15px;
  }
}

.ticket-card {
  @extend %ticket-card;
  transition: 150ms background-color ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: lighten($card-black-background,5%);
  }

  .info-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    @include diagonal-gradient($primary, $primary-states);

    i {
      color: $white;
      font-size: 1.7em;
      padding: 14px 11px;
      transform: rotate(-10deg);
    }

    &.icon-danger {
      @include diagonal-gradient($danger, $danger-states);
    }

    &.icon-warning {
      @include diagonal-gradient($warning, $warning-states);
    }

    &.icon-success {
      @include diagonal-gradient($success, $success-states);
    }

    &.icon-info {
      @include diagonal-gradient($info, $info-states);
    }

    &.icon-primary {
      @include diagonal-gradient($primary, $primary-states);
    }
  }

  .ticket-info {
    text-align: right;
    .card-title {
      margin-top: 0;
      height: 2.9em;
      margin-bottom: 0px;
      font-size: 1.1rem;
      overflow: hidden;
    }
    .ticket-from {
      margin-bottom: 0;
      color: $dark-gray;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .card-footer {
    padding: 0;
  }

  hr {
    border-color: lighten($black, 5%);
  }

  .stats {
    color: rgba($white, 0.6);
    margin: 10px 15px;
    font-size: 0.9em;
    display: inline-block;
  }

  &.card-white {
    hr {
      border-color: rgba(43, 53, 83, 0.2);
    }
  }
}

@include media-breakpoint-down(sm) {
  .ticket-card [class*="col-"] .statistics::after {
    display: none;
  }
}
