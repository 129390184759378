// Modals
// Design element Dialogs
// --------------------------------------------------
.modal-content {
  border: 0;

  // Modal header
  // Top section of the modal w/ title and dismiss

  .modal-header {
    border-bottom: none;

    & button {
      position: absolute;
      right: 27px;
      top: 24px;
      outline: 0;
      padding: 1rem;
      margin: -1rem -1rem -1rem auto;
    }
    .title {
      color: $black;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .modal-title {
      color: $black;
    }
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    line-height: 1.9;

    .ticket-photo {
      overflow: hidden;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      width: 50px;
      height: 50px;
      border: 3px solid $danger;

      > img {

      }
    }
  }
  // Footer (for actions)
  .modal-footer {
    border-top: 0;
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    justify-content: space-between;

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;

      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.modal {

  &.modal-ticket {

    .ticket-info {

      p {
        text-transform: uppercase;

        .badge {
          margin-left: 6px;
          margin-bottom: 0px;
          vertical-align: middle;
        }
        i.fas,
        i.far {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          text-align: center;
        }

        code {
          margin-left: 5px;
          text-transform: none;
        }
      }
    }

    .card-footer {

      .dropdown-menu {
        padding: 5px 10px 10px 10px;

        .nav-item {
          padding: 5px 10px;
          line-height: 30px;

          i {
            width: 20px;
            height: 20px;
            text-align: center;
          }
        }
      }
    }

    &.fade .modal-dialog {
      -webkit-animation-name: fade;
      animation-name: fade;
    }
    &.show .modal-dialog {
      -webkit-transform: translate(0);
      transform: translate(0);
      -webkit-animation-duration: .25s;
      animation-duration: .25s;
      -webkit-animation-name: show;
      animation-name: show;
    }
  }

  &.modal-mini {
    p {
      text-align: center;
    }

    .modal-dialog {
      max-width: 255px;
      margin: 0 auto;
    }

    &.show .modal-dialog {
      -webkit-transform: translate(0, 30%);
      -o-transform: translate(0, 30%);
      transform: translate(0, 30%);
      -webkit-animation-duration: .25s;
      animation-duration: .25s;
    }

    .modal-footer {
      button {
        text-transform: uppercase;
        color: $white;

        &:first-child {
          opacity: 0.5;
        }
      }
    }
  }

  .modal-profile {
    width: 70px;
    height: 70px;
    background-color: $white;
    border-radius: 50%;
    text-align: center;
    line-height: 5.7;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3);

    i {
      color: $primary;
      font-size: 21px;
      margin-top: -10px;
    }

    &[class*="modal-profile-"] {
      i {
        color: $white;
      }
    }

    &.modal-profile-primary {
      background-color: $primary;
    }

    &.modal-profile-danger {
      background-color: $danger;
    }

    &.modal-profile-warning {
      background-color: $warning;
    }
    &.modal-profile-success {
      background-color: $success;
    }

    &.modal-profile-info {
      background-color: $info;
    }
  }

  &.modal-default {
    @include modal-colors($white, $black);
  }

  &.modal-primary {
    @include modal-colors($primary, $white);
  }

  &.modal-danger {
    @include modal-colors($danger, $white);
  }

  &.modal-warning {
    @include modal-colors($warning, $white);
  }

  &.modal-success {
    @include modal-colors($success, $white);
  }

  &.modal-info {
    @include modal-colors($info, $white);
  }

  .modal-header .close {
    color: $danger;
    text-shadow: none;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &.modal-black {
    .modal-content {
      background-color: $black-states;
      color: rgba($white, 0.8);
      .modal-header {
        .modal-title,
        .title {
          color: rgba($white, 0.9);
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }
}

.modal-search {
  .modal-dialog {
    margin: 20px auto;
    max-width: 650px;
    input {
      border: none;
      font-size: 17px;
      font-weight: 100;
    }
    span {
      font-size: 35px;
      color: $search-gray;
    }
  }
  .modal-content {
    .modal-header {
      padding: 24px;
    }
  }

  .modal-header .close {
    color: $dark-background;
    top: 30px !important;
  }

  .modal-footer {
    border-top: 2px solid #f9f9f9;
    margin: 0px 25px 20px;
  }
}

@include media-breakpoint-down(xs) {
  .modal {
    &.show .modal-dialog {
      transform: translateY(10%);

      &.modal-notice {
        transform: translateY(3%);
      }
    }
  }
}
